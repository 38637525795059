import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import promiseFinally from 'promise.prototype.finally';
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import Vue3TouchEvents from "vue3-touch-events";

import App from '@/App';
import "@/assets/css/font-awesome.min.css";
import "@/assets/css/main.less";
import directives from '@/directives';
import permissions from '@/directives/permissions';
import stickyTable from '@/directives/sticky-table';
import router from '@/router';
import store from '@/store';

promiseFinally.shim();

const messages = {
    "ZH_CN": {
        message: {
        }
    },
    "EN-US": {
        message: {
        }
    }
}
const currentHostParts = window.location.hostname.split('.');
let isEnglish = false;
let currentLangParam = '';
const paramIndex = window.location.href.indexOf('&lang=');
if (paramIndex > 0) {
    currentLangParam = window.location.href.substring(paramIndex + 6).split('&')[0];
}else{
    currentLangParam = localStorage.getItem("language") || "";
}
// if (currentHostParts.pop() == "com" && !currentLangParam) {
//     isEnglish = true;
// }
// const currentLanguage = isEnglish ? 'EN-US' : currentLangParam || navigator.language.toUpperCase();
const currentLanguage = "ZH-CN";
if (currentLanguage.indexOf("CN") >= 0) {
    localStorage.setItem("language", "ZH-CN");
} else {
    localStorage.setItem("language", "EN-US");
}

// https://www.appsloveworld.com/reactjs/100/27/how-to-ignore-the-resizeobserver-loop-limit-exceeded-in-testcafe
window.addEventListener('error', e => {
    if(e.message === 'ResizeObserver loop completed with undelivered notifications.' || 
        e.message === 'ResizeObserver loop limit exceeded') {
        e.stopImmediatePropagation();
    }
});

const i18n = createI18n({
    legacy: false,
    locale: currentLanguage,
    fallbackLocale: 'ZH-CN', // set fallback locale
    messages
})
const app = createApp(App).use(i18n).use(store).use(router).use(Antd).use(Vue3TouchEvents).use(permissions).use(stickyTable);
// loadup directives exists for app
['auth', 'flex-wrap-justify-between', 'sticky-route'].map(name => {
    !!directives[name] && app.directive(name, directives[name]);
});
app.mount('#app')

