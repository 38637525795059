let permissions = null;

export function hasPermission(permission) {
    return getPermissions().includes(permission);
}

export default {
    install(app) {
        app.directive('permission', {
            mounted(el, binding, vnode) {
                if (!getPermissions().includes(binding.value)) {
                    // el.parentNode && el.parentNode.removeChild(el);
                    // el.remove();
                    el.style.display = 'none';

                }
            }
        });
    }
};

function getPermissions(params) {
    if (!permissions) {
        let permissionsStr = localStorage.getItem('-USER-BUTTON-PERMISSIONS');
        permissions = permissionsStr ? JSON.parse(permissionsStr) : [];
    }
    return permissions;
}