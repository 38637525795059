// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0;
}
.page-area {
  color: #484B54;
  background-color: #fff;
  margin-bottom: 18px;
  overflow: hidden;
  padding: 12px;
}
.ant-layout {
  /*height:100vh !important;*/
  height: calc(100vh - 50px) !important;
  overflow: hidden;
  display: flex;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#webpack-dev-server-client-overlay {
  display: none;
}
.ant-layout-content {
  overflow: auto;
}
.ant-layout-content::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar,
.ant-drawer-body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 9px;
}
.ant-layout-content::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.ant-drawer-body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 0;
  background-clip: content-box;
  border: 4px solid transparent;
}
.ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: transparent;
  border-radius: 9px;
}
.ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-clip: content-box;
  border: 2px solid transparent;
}
.sticky-box::-webkit-scrollbar {
  display: none !important;
}
:deep(.table-striped) td {
  background-color: #F3F6FB;
}
.ant-divider {
  background-color: #E7EDF7;
}
.ant-divider-horizontal {
  flex-grow: 1;
  margin: 16px 0;
}
.ant-modal {
  color: #484B54;
}
.ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 4px;
}
.ant-modal .ant-modal-header {
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-modal .ant-modal-body {
  padding: 12px 25px;
}
.ant-modal .ant-modal-footer {
  padding: 10px;
  margin-top: 0;
  border-top: 1px solid #f0f0f0;
}
.process-pending-center-wrapper {
  position: absolute;
  right: 25px;
  z-index: 999;
  overflow: hidden;
}
.process-pending-center-wrapper .process-pending-center-alert {
  transition: transform 0.2s;
}
.process-pending-center-wrapper > .ant-alert:first-child {
  margin-top: 8px;
}
.process-pending-center-wrapper .ant-alert {
  margin-top: 5px;
  padding: 8px;
  padding-left: 10px;
  min-width: 320px;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:first-child {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:nth-child(2) {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:nth-child(2) > span:first-child {
  margin: 0 10px;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .pending {
  color: #F9CC45;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .processing {
  color: #F9CC45;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .completed {
  color: #27BA95;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .failed {
  color: #FF0000;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .ant-btn > span {
  font-size: 13px;
}
.process-pending-center-wrapper .ant-alert-warning {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #999;
}
.process-pending-center-wrapper .ant-alert-close-icon .anticon-close {
  color: #fff;
}
.process-pending-center-shrink-wrapper {
  position: absolute;
  right: 0px;
  z-index: 9999;
  background: #00000088;
  height: 60px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.process-pending-center-shrink-wrapper .process-pending-center-shrink-arrow {
  transition: transform 0.2s;
}
.process-pending-center-shrink-wrapper .process-pending-center-shrink-count {
  position: absolute;
  left: -7px;
  top: -7px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: red;
  font-size: 10px;
  padding: 0px 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.upcase,
.ant-descriptions-item-label,
.ant-table-header-column {
  text-transform: capitalize !important;
}
.button-container {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button-container button {
  margin-right: 30px;
}
.button-container button:last-child {
  margin: 0;
}
.tip-info {
  color: #aaa;
}
.form-label {
  height: 30px;
  line-height: 30px;
  text-align: right;
  float: right;
  padding-right: 6px;
}
.form-radio-container {
  padding-top: 6px;
}
.form-row {
  margin: 12px;
}
.form-controller-container {
  display: flex;
}
.default-blue-tip {
  font-size: 12px;
  line-height: inherit;
  padding: 14px 20px;
  vertical-align: middle;
  color: #002da0;
  border: 1px solid #d5e7ff;
  border-radius: 0;
  background: #d5e7ff;
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.menu-collapse-btn {
  border: none;
  margin-top: 10px;
}
.menu-collapse-btn:active,
.menu-collapse-btn:focus,
.menu-collapse-btn:hover {
  color: #fff;
  border: none;
}
.comp-selector-wrapper .ant-popover-inner-content {
  padding: 3px 0px;
  min-width: 150px;
  overflow-y: auto;
  max-height: 300px;
}
.user-dropdown-menu-wrapper .ant-popover-inner-content {
  padding: 0;
}
.user-dropdown-menu-wrapper .ant-popover-arrow-content:before {
  background-color: #fff;
}
.user-dropdown-menu-wrapper .ant-popover-content .ant-popover-arrow {
  border-top-color: #fff;
  border-left-color: #fff;
}
.user-dropdown-menu-wrapper .ant-popover-inner {
  background: #fff;
  width: 200px;
  overflow: hidden;
  padding: 6px 0;
}
.user-dropdown-menu-wrapper .menu-title {
  width: 100%;
  overflow: hidden;
  line-height: 24px;
  padding: 12px 18px;
  color: #999;
  padding-bottom: 8px;
}
.user-dropdown-menu-wrapper .menu-title .username {
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 18px;
  padding-bottom: 6px;
  color: #1c2431;
}
.user-dropdown-menu-wrapper .menu-title .menu-item {
  line-height: 20px;
  color: #1c2431;
  font-size: 14px;
}
.user-dropdown-menu-wrapper .menu-title .menu-item a {
  color: #fff;
}
.user-dropdown-menu-wrapper .menu-title .menu-item.clickable-item a {
  color: #1c2431;
}
.user-dropdown-menu-wrapper .user-menu-list {
  overflow: hidden;
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item {
  font-size: 14px;
  line-height: 26px;
  padding: 6px 18px;
  position: relative;
  color: #1c2431;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item .user-icon {
  color: #0abe5b;
  font-size: 6px;
  position: absolute;
  left: 30px;
  top: 20px;
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item:hover {
  cursor: pointer;
}
.user-dropdown-menu-wrapper .user-menu-list .router-link :deep(a) {
  color: #1c2431;
}
.user-dropdown-menu-wrapper .user-menu-list .split-line {
  height: 1px;
  width: 100%;
  background: #666;
  margin: 6px 0;
}
.no-padding-popover .ant-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.black-bg .ant-modal .ant-modal-content .ant-modal-body {
  background-color: #000;
  color: #fff;
}
.black-bg .ant-modal .ant-modal-content .ant-modal-close {
  color: #fff;
}
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
}
.modal-thin-padding .ant-modal-body {
  padding: 10px 24px 15px;
}
.flex-wrap-justify-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.controller-col {
  display: flex;
  align-items: center;
}
/* if device has a touch screen and in portrait mode*/
@media (any-pointer: coarse) and (orientation: portrait) {
  .inner-menu-container.inner-menu-container-min {
    width: 0px;
    padding-right: 0;
  }
  .ant-layout-header.content-layout-header-wrapper {
    height: 55px ;
    line-height: 55px;
  }
  .ant-layout-header.content-layout-header-wrapper .trigger {
    font-size: 25px;
  }
  .ant-layout-header.content-layout-header-wrapper .ant-breadcrumb {
    font-size: 18px;
  }
  .body-container .system-header {
    height: 70px;
    min-height: 70px;
  }
  .body-container .system-header .title .logo-wrapper {
    height: auto;
    margin-top: -10px;
  }
  .body-container .system-header .title .project-name-wrapper .logo-title {
    font-size: 16px;
  }
  .body-container .system-header .internal-mode-tip {
    height: 70px;
    line-height: 70px;
    flex: none;
  }
  .body-container .system-header .right-area {
    height: auto;
  }
  .body-container .system-header .right-area .language-selector-wrapper {
    display: flex;
    align-items: center;
  }
  .body-container .system-header .right-area .language-selector-wrapper .comp-selector-btn.ant-btn {
    font-size: 15px;
  }
  .body-container .system-header .right-area .partners-selector-wrapper .comp-selector-btn,
  .body-container .system-header .right-area .partners-selector-wrapper .label {
    font-size: 15px;
  }
}
body {
  padding: 0;
}
.page-area {
  color: #484B54;
  background-color: #fff;
  margin-bottom: 18px;
  overflow: hidden;
  padding: 12px;
}
.ant-layout {
  /*height:100vh !important;*/
  height: calc(100vh - 50px) !important;
  overflow: hidden;
  display: flex;
}
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#webpack-dev-server-client-overlay {
  display: none;
}
.ant-layout-content {
  overflow: auto;
}
.ant-layout-content::-webkit-scrollbar,
.ant-table-body::-webkit-scrollbar,
.ant-drawer-body::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-radius: 9px;
}
.ant-layout-content::-webkit-scrollbar-thumb,
.ant-table-body::-webkit-scrollbar-thumb,
.ant-drawer-body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  border-radius: 0;
  background-clip: content-box;
  border: 4px solid transparent;
}
.ant-table-content::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: transparent;
  border-radius: 9px;
}
.ant-table-content::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-clip: content-box;
  border: 2px solid transparent;
}
.sticky-box::-webkit-scrollbar {
  display: none !important;
}
:deep(.table-striped) td {
  background-color: #F3F6FB;
}
.ant-divider {
  background-color: #E7EDF7;
}
.ant-divider-horizontal {
  flex-grow: 1;
  margin: 16px 0;
}
.ant-modal {
  color: #484B54;
}
.ant-modal .ant-modal-content {
  padding: 0;
  border-radius: 4px;
}
.ant-modal .ant-modal-header {
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid #f0f0f0;
}
.ant-modal .ant-modal-body {
  padding: 12px 25px;
}
.ant-modal .ant-modal-footer {
  padding: 10px;
  margin-top: 0;
  border-top: 1px solid #f0f0f0;
}
.process-pending-center-wrapper {
  position: absolute;
  right: 25px;
  z-index: 999;
  overflow: hidden;
}
.process-pending-center-wrapper .process-pending-center-alert {
  transition: transform 0.2s;
}
.process-pending-center-wrapper > .ant-alert:first-child {
  margin-top: 8px;
}
.process-pending-center-wrapper .ant-alert {
  margin-top: 5px;
  padding: 8px;
  padding-left: 10px;
  min-width: 320px;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message {
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:first-child {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:nth-child(2) {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message > span:nth-child(2) > span:first-child {
  margin: 0 10px;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .pending {
  color: #F9CC45;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .processing {
  color: #F9CC45;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .completed {
  color: #27BA95;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .failed {
  color: #FF0000;
}
.process-pending-center-wrapper .ant-alert .ant-alert-message .ant-btn > span {
  font-size: 13px;
}
.process-pending-center-wrapper .ant-alert-warning {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #999;
}
.process-pending-center-wrapper .ant-alert-close-icon .anticon-close {
  color: #fff;
}
.process-pending-center-shrink-wrapper {
  position: absolute;
  right: 0px;
  z-index: 9999;
  background: #00000088;
  height: 60px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.process-pending-center-shrink-wrapper .process-pending-center-shrink-arrow {
  transition: transform 0.2s;
}
.process-pending-center-shrink-wrapper .process-pending-center-shrink-count {
  position: absolute;
  left: -7px;
  top: -7px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: red;
  font-size: 10px;
  padding: 0px 4px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.upcase,
.ant-descriptions-item-label,
.ant-table-header-column {
  text-transform: capitalize !important;
}
.button-container {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button-container button {
  margin-right: 30px;
}
.button-container button:last-child {
  margin: 0;
}
.tip-info {
  color: #aaa;
}
.form-label {
  height: 30px;
  line-height: 30px;
  text-align: right;
  float: right;
  padding-right: 6px;
}
.form-radio-container {
  padding-top: 6px;
}
.form-row {
  margin: 12px;
}
.form-controller-container {
  display: flex;
}
.default-blue-tip {
  font-size: 12px;
  line-height: inherit;
  padding: 14px 20px;
  vertical-align: middle;
  color: #002da0;
  border: 1px solid #d5e7ff;
  border-radius: 0;
  background: #d5e7ff;
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.menu-collapse-btn {
  border: none;
  margin-top: 10px;
}
.menu-collapse-btn:active,
.menu-collapse-btn:focus,
.menu-collapse-btn:hover {
  color: #fff;
  border: none;
}
.comp-selector-wrapper .ant-popover-inner-content {
  padding: 3px 0px;
  min-width: 150px;
  overflow-y: auto;
  max-height: 300px;
}
.user-dropdown-menu-wrapper .ant-popover-inner-content {
  padding: 0;
}
.user-dropdown-menu-wrapper .ant-popover-arrow-content:before {
  background-color: #fff;
}
.user-dropdown-menu-wrapper .ant-popover-content .ant-popover-arrow {
  border-top-color: #fff;
  border-left-color: #fff;
}
.user-dropdown-menu-wrapper .ant-popover-inner {
  background: #fff;
  width: 200px;
  overflow: hidden;
  padding: 6px 0;
}
.user-dropdown-menu-wrapper .menu-title {
  width: 100%;
  overflow: hidden;
  line-height: 24px;
  padding: 12px 18px;
  color: #999;
  padding-bottom: 8px;
}
.user-dropdown-menu-wrapper .menu-title .username {
  padding: 0;
  margin: 0;
  line-height: 20px;
  font-size: 18px;
  padding-bottom: 6px;
  color: #1c2431;
}
.user-dropdown-menu-wrapper .menu-title .menu-item {
  line-height: 20px;
  color: #1c2431;
  font-size: 14px;
}
.user-dropdown-menu-wrapper .menu-title .menu-item a {
  color: #fff;
}
.user-dropdown-menu-wrapper .menu-title .menu-item.clickable-item a {
  color: #1c2431;
}
.user-dropdown-menu-wrapper .user-menu-list {
  overflow: hidden;
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item {
  font-size: 14px;
  line-height: 26px;
  padding: 6px 18px;
  position: relative;
  color: #1c2431;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item .user-icon {
  color: #0abe5b;
  font-size: 6px;
  position: absolute;
  left: 30px;
  top: 20px;
}
.user-dropdown-menu-wrapper .user-menu-list .user-menu-item:hover {
  cursor: pointer;
}
.user-dropdown-menu-wrapper .user-menu-list .router-link :deep(a) {
  color: #1c2431;
}
.user-dropdown-menu-wrapper .user-menu-list .split-line {
  height: 1px;
  width: 100%;
  background: #666;
  margin: 6px 0;
}
.no-padding-popover .ant-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.black-bg .ant-modal .ant-modal-content .ant-modal-body {
  background-color: #000;
  color: #fff;
}
.black-bg .ant-modal .ant-modal-content .ant-modal-close {
  color: #fff;
}
.full-modal .ant-modal {
  max-width: 100%;
  top: 0;
  padding-bottom: 0;
  margin: 0;
}
.full-modal .ant-modal-content {
  display: flex;
  flex-direction: column;
  height: calc(100vh);
}
.full-modal .ant-modal-body {
  flex: 1;
}
.modal-thin-padding .ant-modal-body {
  padding: 10px 24px 15px;
}
.flex-wrap-justify-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.controller-col {
  display: flex;
  align-items: center;
}
/* if device has a touch screen and in portrait mode*/
@media (any-pointer: coarse) and (orientation: portrait) {
  .inner-menu-container.inner-menu-container-min {
    width: 0px;
    padding-right: 0;
  }
  .ant-layout-header.content-layout-header-wrapper {
    height: 55px ;
    line-height: 55px;
  }
  .ant-layout-header.content-layout-header-wrapper .trigger {
    font-size: 25px;
  }
  .ant-layout-header.content-layout-header-wrapper .ant-breadcrumb {
    font-size: 18px;
  }
  .body-container .system-header {
    height: 70px;
    min-height: 70px;
  }
  .body-container .system-header .title .logo-wrapper {
    height: auto;
    margin-top: -10px;
  }
  .body-container .system-header .title .project-name-wrapper .logo-title {
    font-size: 16px;
  }
  .body-container .system-header .internal-mode-tip {
    height: 70px;
    line-height: 70px;
    flex: none;
  }
  .body-container .system-header .right-area {
    height: auto;
  }
  .body-container .system-header .right-area .language-selector-wrapper {
    display: flex;
    align-items: center;
  }
  .body-container .system-header .right-area .language-selector-wrapper .comp-selector-btn.ant-btn {
    font-size: 15px;
  }
  .body-container .system-header .right-area .partners-selector-wrapper .comp-selector-btn,
  .body-container .system-header .right-area .partners-selector-wrapper .label {
    font-size: 15px;
  }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
